import axios, { AxiosError } from 'axios';

import { showAlert } from '../helpers/show-toast';
import { logout } from '../store/auth';
import { ApiRequestParams } from '../types/api';

export const apiRequest = async ({
  errorToast = {
    show: true,
  },
  redirect = true,
  ...options
}: ApiRequestParams) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const res = await axios.request({
      // baseURL: 'https://api2.99ballov.wocom.biz/v1admin',
      // baseURL: 'https://api.99ballov.ru/v1admin',
      // baseURL: 'https://apiclone99ballov.wocom.biz/v1admin',
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
        ...options.headers,
      },
      ...options,
    });

    if (res.data.error && errorToast?.show && options.method !== 'post' && process.env.NODE_ENV === 'development') {
      showAlert({
        message: res?.data?.data.slice(0, 150),
        type: 'info',
      });
    }

    if (res?.data.status === 404 && redirect) {
      // history.go(-1);
      window.location.replace('/404');
    }

    return res.data;
  } catch (_error) {
    const { response, message } = _error as AxiosError;

    if (message === 'Network Error') {
      // showAlert({ message: 'Проверьте подключение к интеренету', alertTitle: 'Отсутвует интернет' });

      return;
    }
    if (response?.status === 401) {
      logout();
    } else {
      if (response?.status === 403) {
        window.location.replace('/403');
        showAlert({
          message: 'У вас нет прав доступа',
        });
      }

      // TODO: можно убрать, если это заплатка от ошибки "падения интернета"
      if (errorToast?.show && message !== 'canceled' && process.env.NODE_ENV === 'development') {
        showAlert({
          message: errorToast.text || `${message} ${options.url}`,
        });
      }
    }

    // TODO: а для чего это было сделано? Из-за этого сервер после 401 ошибки еще все остальные запросы тиранят, сделал фикс в use-auth-check.ts:27
    return {
      data: response?.data,
      error: true,
      status: response?.status,
    };
  }
};
